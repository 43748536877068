import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import PostTags from '../components/PostTags';
import SEO from '../components/SEO';
import ConfigContext from '../context/ConfigContext';
import './b16-tomorrow-dark.css';
import './post.css';

export default function PostTemplate({ data, pageContext }) {
  const config = useContext(ConfigContext);
  const { slug } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;

  return (
    <Layout>
      <div className='flex flex-auto flex-shrink-0 flex-row'>
        <Helmet>
          <title>{`${post.title} | ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />
        <div className='flex flex-1 flex-col'>
          {/* eslint-disable-next-line react/no-danger */}
          <div className='post-body prose prose-xl' dangerouslySetInnerHTML={{ __html: postNode.html }} />
          <div className='post-meta'>
            <PostTags tags={post.tags} />
            {/*
             <SocialLinks postPath={slug} postNode={postNode} mobile={false} />
             */}
          </div>
          {/*
          <UserInfo expanded={false} />
          <Disqus postNode={postNode} />
          */}
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
